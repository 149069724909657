<template>
  <MerchantAppLayout v-if="authIsReady" class="bg-gray-100">
    <template v-slot:toolbar>
      <CNavBar>
        <template v-slot:title>
          <CTypo tstyle="title3" class="mr-6">
            contimo
          </CTypo>
        </template>
        <CNavBarItem to="/m/home">
          {{ $t('home') }}
        </CNavBarItem>
        <template v-if="!needsOnboarding">
          <CNavBarItem to="/m/settings">
            {{ $t('settings') }}
          </CNavBarItem>
        </template>
        <template v-slot:right>
          <CNavBarItem
            v-if="shopUrl && thisMerchant.paymentOnboarded"
            :native-href="`${shopUrl}?tok=${accessToken}`"
            class="mr-2 hidden md:inline-flex"
            target="_blank"
          >
            {{ $t('shopPreview') }} <CIcon name="external-link-outline" size="20" class="ml-1" />
          </CNavBarItem>
          <div @click="showSentFeedbackModal = true">
            <CNavBarItem
              class="mr-2 hidden md:inline-flex cursor-pointer"
            >
              {{ $t('sentFeedback') }} <CIcon name="mail-outline" size="20" class="ml-1" />
            </CNavBarItem>
          </div>

          <CDropdownMenu :label="thisMerchant.name" position="bottom-left" light>
            <!-- <CDropdownMenuItem icon="user-circle">{{ $t('myAccount') }}</CDropdownMenuItem> -->
            <template v-if="['manager', 'admin'].includes(thisUser.manager.role)">
              <CDropdownMenuItem icon="document-text-outline" @click="$router.push('/m/invoices')">
                {{ $t('invoices') }}
              </CDropdownMenuItem>
              <CDivider no-spacing light class="my-1" />
            </template>
            <CDropdownMenuItem icon="logout-outline" @click="logoutAndRedirect">
              {{ $t('logout') }}
            </CDropdownMenuItem>
          </CDropdownMenu>
        </template>
      </CNavBar>
      <CNavSub
        v-show="showSubNavbar"
        :divider="showSubNavbarContent"
        :scrolled="subnavScrolled"
      >
        <PortalTarget
          name="subnav"
          multiple
          @change="handleShowSubnav"
        />
      </CNavSub>
    </template>
    <div
      v-show="showSubNavbarContent"
      ref="subnavContent"
      class="bg-white border-b shadow-sm"
    >
      <CContainer>
        <PortalTarget
          name="subnav-content"
          slim
          @change="handleShowSubnavContent"
        />
      </CContainer>
    </div>
    <router-view />
    <template v-slot:footer>
      <div class="mb-3 text-center">
        <CLink
          v-for="link in footerLinks"
          :key="link.href"
          :href="link.href"
          class="text-gray-500 inline-flex mx-2"
          small
          target="_blank"
        >
          {{ link.label }}
        </CLink>
      </div>
      <CTypo tstyle="footnote2" class="text-gray-600 text-xs text-center pb-6">
        © 2021 contimo GmbH<template v-if="appVersion"> – Version: {{ appVersion }}</template>
      </CTypo>
    </template>
    <Portal to="overlays">
      <FeedbackModal v-model="showSentFeedbackModal" />
    </Portal>
  </MerchantAppLayout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  AUTH_IS_READY,
  THIS_MERCHANT,
  NEEDS_ONBOARDING,
  WINDOW_SCROLL,
  SHOP_URL,
  THIS_SHOP,
  ACCESS_TOKEN,
  THIS_USER,
  APP_VERSION,
} from '@/store/gettersTypes';
import { CONTACT, IMPRINT, PRIVACY, TERMS } from '@/constants/links';
import { GET_SHOP, LOGOUT } from '@/store/actionTypes';
import { SET_APP_LOADING } from '@/store/mutationsTypes';
import MerchantAppLayout from '@/ui/layouts/MerchantApp.vue';
import FeedbackModal from '@/ui/components/Merchant/FeedbackModal.vue';

export default {
  components: {
    MerchantAppLayout,
    FeedbackModal,
  },

  data: () => ({
    showSubNavbar: false,
    showSubNavbarContent: false,
    routeChanged: false,
    showSentFeedbackModal: false,
  }),

  computed: {
    footerLinks() {
      return [
        { href: CONTACT, label: this.$t('contact') },
        { href: TERMS, label: this.$t('terms') },
        { href: PRIVACY, label: this.$t('privacy') },
        { href: IMPRINT, label: this.$t('imprint') },
      ];
    },
    ...mapGetters([
      AUTH_IS_READY,
      THIS_MERCHANT,
      NEEDS_ONBOARDING,
      WINDOW_SCROLL,
      SHOP_URL,
      THIS_SHOP,
      THIS_USER,
      ACCESS_TOKEN,
      APP_VERSION,
    ]),
    subnavScrolled() {
      if (this.showSubNavbarContent) {
        return this.windowScroll.y > this.$refs.subnavContent.offsetHeight - 10;
      }
      return this.windowScroll.y > 5;
    },
  },

  created() {
    if (this.thisMerchant.paymentOnboarded) {
      this.getShop();
    }
  },

  methods: {
    ...mapMutations([SET_APP_LOADING]),
    ...mapActions([GET_SHOP]),
    logoutAndRedirect() {
      this.setAppLoading(true);
      setTimeout(() => {
        this.$store.dispatch(LOGOUT, () => {
          this.$router.push('/login');
          setTimeout(() => {
            window.location.reload();
          }, 500);
        });
      }, 500);
    },
    handleShowSubnav(newVal) {
      if (newVal) {
        this.showSubNavbar = true;
      } else {
        this.showSubNavbar = false;
      }
    },
    handleShowSubnavContent(newVal) {
      if (newVal) {
        this.showSubNavbarContent = true;
      } else {
        this.showSubNavbarContent = false;
      }
    },
  },
};
</script>
